import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { MOCK_DATA1 } from "../mock/mock";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import {
  IoPlaySkipForwardOutline,
  IoPlaySkipBackOutline,
} from "react-icons/io5";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";
import { IoIosRefresh } from "react-icons/io";
import { Menu, Transition } from "@headlessui/react";
import DropDown from "utils/drop-down";
import Button from "./button";

interface TableProps {
  data: any[];
  columns: any[];
  isExpandable?: boolean;
  isFilterable?: boolean;
  isSortable?: boolean;
  isPaginated?: boolean;
  pageSize?: number;
  isSelectable?: boolean;
  headerBgColor?: string;
  headerTextColor?: string;
  rowBgColor?: string;
  rowTextColor?: string;
  rowHoverBgColor?: string;
  onRowClick?: (row: any) => void;
}

const Table = ({
  data,
  columns,
  isExpandable = false,
  isFilterable = false,
  isSortable = false,
  isPaginated = false,
  pageSize = 10,
  isSelectable = false,
  headerBgColor = "bg-gray-200",
  headerTextColor = "text-gray-700",
  rowBgColor = "bg-white",
  rowTextColor = "text-gray-700",
  rowHoverBgColor = "hover:bg-gray-200",
  onRowClick,
}: TableProps) => {
  const [sorting, setSorting] = useState<any>();
  const [page, setPage] = useState(0);

  const tableInstance = useReactTable({
    data: useMemo(() => data, []),
    columns: useMemo(() => columns, []),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  if (data.length === 0) {
    return (
      <div className="w-full p-4 font-bold text-center text-gray-700 bg-gray-200 border border-gray-300">
        No record found
      </div>
    );
  }

  return (
    <div className="w-full overflow-x-auto">
      <table
        width={tableInstance.getTotalSize()}
        className="w-full text-sm text-left whitespace-no-wrap bg-white border border-collapse border-gray-800 divide-y divide-gray-800 table-auto"
      >
        <thead className="py-2 text-xs font-bold uppercase bg-gray-200 divide-y divide-gray-800">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="text-xs uppercase bg-gray-200">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{ width: header.column.getSize() }}
                  className="relative px-4 py-2 font-bold border border-gray-300 text-md "
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {!header.column.getIsSorted() ? null : header.column.getIsSorted() ==
                    "asc" ? (
                    <BsArrowUp className="inline-block ml-2 text-primary" />
                  ) : (
                    <BsArrowDown className="inline-block ml-2 text-red-500" />
                  )}
                  {isFilterable && <Filter />}
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`absolute opacity-0 top-0 right-0 h-full w-1 bg-black cursor-col-resize hover:opacity-50 hover:bg-black ${
                      header.column.getIsResizing() ? "opacity-100" : ""
                    }`}
                  ></div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-gray-700">
          {tableInstance.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="text-gray-600 odd:bg-gray-100 even:bg-gray-200 hover:bg-gray-300 active:bg-primary"
              onClick={() => onRowClick && onRowClick(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  className="px-4 py-2 border border-gray-300"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot className="py-2 text-xs font-bold uppercase bg-gray-200 divide-y divide-gray-800">
          {tableInstance.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id} className="text-xs uppercase bg-gray-200">
              {footerGroup.headers.map((footer) => (
                <th
                  key={footer.id}
                  style={{ width: footer.column.getSize() }}
                  className="relative px-4 py-2 font-bold border border-gray-300 text-md "
                  onClick={footer.column.getToggleSortingHandler()}
                >
                  {footer.isPlaceholder
                    ? null
                    : flexRender(
                        footer.column.columnDef.footer,
                        footer.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="flex items-center justify-between px-4 py-2 bg-gray-200 border border-gray-300 ">
        <div className="flex items-center">
          <button
            disabled={!tableInstance.getCanPreviousPage()}
            onClick={() => {
              if (tableInstance.getCanPreviousPage()) {
                tableInstance.previousPage();
              }
              if (tableInstance.getCanPreviousPage()) {
                tableInstance.previousPage();
              }
            }}
          >
            <IoPlaySkipBackOutline className="text-xs p-1.5 h-8 w-8 rounded-full border-2 border-gray-300 hover:bg-gray-300" />
          </button>
          <button
            disabled={!tableInstance.getCanPreviousPage()}
            onClick={() => tableInstance.previousPage()}
          >
            <GrCaretPrevious className="text-xs p-1.5 h-8 w-8 rounded-full border-2 border-gray-300 hover:bg-gray-300" />
          </button>
          <Menu as="div" className="relative inline-block text-left">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute w-16 bg-gray-300 -top-28">
                {tableInstance.getPageOptions().map((option, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-primary text-white" : "text-gray-900"
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => {
                          tableInstance.setPageIndex(option);
                          setPage(option);
                        }}
                      >
                        {option + 1}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
            <Menu.Button>
              {
                <button className="flex items-center justify-center px-6 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-full shadow-sm">
                  {page + 1}
                </button>
              }
            </Menu.Button>
          </Menu>

          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={() => tableInstance.nextPage()}
          >
            <GrCaretNext className="text-xs p-1.5 h-8 w-8 rounded-full border-2 border-gray-300 hover:bg-gray-300" />
          </button>
          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={() => {
              if (tableInstance.getCanNextPage()) {
                tableInstance.nextPage();
              }
              if (tableInstance.getCanNextPage()) {
                tableInstance.nextPage();
              }
            }}
          >
            <IoPlaySkipForwardOutline className="text-xs p-1.5 h-8 w-8 rounded-full border-2 border-gray-300 hover:bg-gray-300" />
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              tableInstance.reset();
            }}
          >
            <IoIosRefresh />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;

const Filter = () => {
  return (
    <Menu>
      <Menu.Button>
        <div
          onClick={(e) => {
            // e.stopPropagation();
          }}
          className="absolute right-0 p-2 text-gray-500 transform -translate-y-1/2 cursor-pointer top-1/2 hover:text-gray-700"
        >
          <FaFilter />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 px-2 py-1 mt-4 overflow-auto text-sm bg-white border border-gray-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm w-52">
          <div>
            <h1 className="text-xs">Select items with values that</h1>
            <DropDown
              label=""
              options={[
                "Equal to",
                "Less than or equal to",
                "Less than",
                "greater than or equal to",
                "greater than",
                "not equal to",
              ]}
            />
            <input
              className="w-full p-2 px-2 py-1 mt-4 border border-gray-300 rounded-md "
              type="number"
            />
            <DropDown label="" options={["And", "Or"]} />
            <DropDown
              label=""
              options={[
                "Contains",
                "Does not contain",
                "Is equal to",
                "It's not equal to",
                "Starts with",
                "Ends with",
              ]}
            />
            <input
              className="w-full p-2 px-2 py-1 mt-4 border border-gray-300 rounded-md "
              type="number"
            />
            <div className="flex items-center justify-between my-2">
              <Button>Filter</Button>
              <Button variant="secondary">Clear</Button>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
