import { BsBarChart } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { FaGear } from "react-icons/fa6";
import { BiSolidSpreadsheet } from "react-icons/bi";

let userRole = localStorage.getItem("userRole");

export const navLinks = [
  {
    parent: "Report",
    icon: <BsBarChart className="text-xl" />,
    dropDown: [
      { label: "Retail Revenue", link: "/Retail-revenue" },
      { label: "Retail User Revenue", link: "/Retail-user-revenue" },
    ],
  },
  {
    parent: "Find",
    icon: <AiOutlineSearch className="text-xl" />,
    dropDown: [
      {
        label: "Retail Bets",
        link: "/Retail-bets",
      },
    ],
  },

  {
    parent: "Setup",
    icon: <FaGear className="text-xl" />,
    dropDown: [
      {
        label: "Back Office Users",
        link: "/Bank-office-users",
      },
      {
        label: "odd",
        link: "/odd",
      },
    ],
  },

  {
    parent: "Retail",
    icon: <BiSolidSpreadsheet className="text-xl" />,
    dropDown: [
      localStorage.getItem("userRole") != "retail_finance" && {
        label: "Retail Users",
        link: "/Retail-users",
      },
      { label: "Retailers", link: "/Retailers" },
    ],
  },
];
