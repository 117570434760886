import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { CheckBox, Table } from "components";
import Button from "components/button";
import { useAppDispatch, useAppSelector } from "stores/store";
import {
  assignCashirToShopAction,
  updateCashierAction,
  unassignCashirAction,
} from "stores/ReatilerUser/retailerAction";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const roleOptions = ["Cashier"];

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const General = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isTestUser, setIsTestUser] = useState(false);
  const [role, setRole] = useState("");

  const dispatch = useAppDispatch();
  const retailUser = useAppSelector((state) => state.retailer.retailUsers);
  const currentUsers = useAppSelector((state) => state);
  const location = useLocation();

  const handleUpdateUser = async () => {
    if (firstName !== "" && lastname !== "" && username !== "") {
      if (lastname.length > 1 && firstName.length > 1 && username.length > 1) {
        try {
          const response = await dispatch(
            updateCashierAction({
              firstName,
              lastname,
              username,
              role,
              currentUserId: currentUser._id,
            })
          );
          if (response.payload) {
            Notification({
              message: "User successfully updated",
              type: "success",
            });
          } else {
            Notification({
              message: "Something went wrong! Please try again",
              type: "error",
            });
          }
        } catch (error) {
          Notification({
            message: "Something went wrong!",
            type: "error",
          });
        }
      } else {
        Notification({
          message: "Please fill in all required fields",
          type: "error",
        });
      }
    } else {
      Notification({
        message: "Please fill in all required fields",
        type: "error",
      });
    }
  };

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");

    //look for that user id from the users list
    retailUser.length > 0 &&
      setCurrentUser(retailUser.find((user) => user._id === rowParam));
    setFirstName(currentUser.firstname);
    setLastname(currentUser.lastname);
    setUsername(currentUser.username);
    setRole(currentUser.role);
    // setIsTestUser(currentUser.isTestUser)  not implemented on backednd
  }, [currentUser]);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-5">
        <div>
          <label htmlFor="name">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            name="name"
            id="name"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="surname">
            Surname <span className="text-red-500">*</span>
          </label>
          <input
            name="surname"
            id="surname"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="Username">
            Username <span className="text-red-500">*</span>
          </label>
          <input
            name="Username"
            id="Username"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-5">
        <h1 className="text-lg font-bold">
          Role(s) <span className="text-red-500">*</span>
        </h1>

        <div>
          {roleOptions.map((val, i) => {
            return (
              <div key={i} className="flex gap-2 mb-2">
                <input
                  name="role"
                  key={i}
                  className="px-2 py-1 border-2 rounded-md "
                  radioGroup="role"
                  type="radio"
                  checked={val === role}
                  onChange={() => setRole((prevRole) => val)}
                />
                <label htmlFor={`role-${i}`}>{val}</label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end py-5 mt-5 border-t-2 ">
        <Button onClick={handleUpdateUser}>
          <div>Update</div>
        </Button>
      </div>
    </div>
  );
};

const RetailUsers = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const [key, setKey] = useState(0);
  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkedRowsToUnassign, setcheckedRowsToUnassign] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const retailers = useAppSelector((state) => state.retailer.retailers);
  const retailUser = useAppSelector((state) => state.retailer.retailUsers);

  const handAssignToShop = async () => {
    if (checkedRows.length > 0) {
      var selectedShopId = checkedRows[0]._id;
      try {
        const response = await dispatch(
          assignCashirToShopAction({
            retailerId: selectedShopId,
            currentuserId: currentUser._id,
          })
        );
        if (response.payload) {
          Notification({
            message: "User successfully assigned to retailer",
            type: "success",
          });
          window.history.back();
        } else {
          Notification({
            message: "Something went wrong! Please try again",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };
  const handleUnassign = async () => {
    if (checkedRowsToUnassign.length > 0) {
      var selectedShopId = checkedRowsToUnassign[0]._id;
      try {
        const response = await dispatch(
          unassignCashirAction({ currentuserId: currentUser._id })
        );
        if (response.payload) {
          Notification({
            message: "User successfully unassigned from retailer",
            type: "success",
          });
          window.history.back();
        } else {
          Notification({
            message: "Something went wrong! Please try again",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const handlecheckedrow = (row: any) => {
    setCheckedRows((prevRows) => [row]);
  };
  const handlecheckedrowToUnassign = (row: any) => {
    checkedRowsToUnassign.length > 0
      ? setcheckedRowsToUnassign((prevRows) => [])
      : setcheckedRowsToUnassign((prevRows) => [row]);
    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");
    retailUser.length > 0 &&
      setCurrentUser(retailUser.find((user) => user._id === rowParam));
    setKey((prevKey) => prevKey + 1);
  }, [currentUser]);

  useEffect(() => {
    //Remove Duplicate for assign
    const uniqueIds = new Set();
    checkedRows.some((obj) => {
      if (uniqueIds.has(obj._id)) {
        setCheckedRows((prevRows) =>
          prevRows.filter((prevRow) => String(prevRow._id) !== String(obj._id))
        );
      }
      uniqueIds.add(obj._id);
    });
    setKey((prevKey) => prevKey + 1);
  }, [checkedRows]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-5 ">
        <h1 className="font-[600]">
          Assigned Retailers (Total:{" "}
          {
            retailers.filter((user) => user._id === currentUser.assignedTo)
              .length
          }{" "}
          )
        </h1>
        <div className="flex flex-col gap-5 bg-gray-200">
          <Table
            key={key}
            data={
              retailers.filter((user) => user._id === currentUser.assignedTo) //get the assigned shop from shop lists
            }
            columns={[
              {
                header: "",
                id: "checkbox",
                size: 10,
                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrowToUnassign(row.row.original);
                  };
                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        checked={
                          checkedRowsToUnassign.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  );
                },
              },

              {
                header: "Retailer Name",
                accessorKey: "username",
              },

              {
                header: "",
                id: "action",
                cell: (row: any) => {
                  return (
                    <div className="flex items-center justify-start">
                      <Link
                        to={{
                          pathname: "/Retailer-detail",
                          search: `?param1=${row.row.original._id}&param2=retailer`,
                        }}
                        className="block px-4 py-2 text-sm text-gray-100 bg-gray-700 border rounded-md"
                      >
                        View Detail
                      </Link>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handleUnassign}>
            <div>Unassign Selected</div>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-5 ">
        <h1 className="font-[600]">
          Unassigned Retailers (Total{" "}
          {
            retailers.filter((user) => user._id !== currentUser.assignedTo)
              .length
          }
          )
        </h1>
        <div>
          <Table
            data={retailers.filter(
              (user) => user._id !== currentUser.assignedTo
            )}
            key={key}
            columns={[
              {
                header: "",
                id: "checkbox",
                size: 10,
                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrow(row.row.original);
                  };
                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        checked={
                          checkedRows.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  );
                },
              },
              {
                header: "name",
                accessorKey: "username",
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handAssignToShop}>
            <div>Assign Selected</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  { name: "General", tab: <General /> },
  { name: "Retailers", tab: <RetailUsers /> },
];

const RetailerUserDetail = () => {
  const retailUser = useAppSelector((state) => state.retailer.retailUsers);
  return (
    <div key={retailUser.length}>
      <h1 className="mb-2 text-xl">Manage Retail User</h1>
      <div className="p-1 bg-gray-300">
        <Tab.Group>
          <Tab.List className="bg-gray-300">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) => `border border-gray-500
                px-4 py-2 rounded-md 
                rounded-b-none
                focus:outline-none focus:ring-transparent ring-offset-2 ring-offset-gray-300 ring-white
                ${selected ? "bg-white border-b-white" : "bg-gray-300"}
                `}
              >
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="px-5 bg-white border-gray-500">
            {tabs.map((tab) => (
              <Tab.Panel key={tab.name} className="p-5 rounded-md ">
                {tab.tab}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="flex justify-center mt-5">
        <Button variant="secondary" onClick={() => window.history.back()}>
          <div>Back to List</div>
        </Button>
      </div>
    </div>
  );
};

export default RetailerUserDetail;
