import { Menu, Transition } from "@headlessui/react";
import React, { useState, useEffect } from "react";
import {
  CheckBox,
  Table,
  Pagination,
  DatePicker,
  ChipSelect,
  BasicTable,
} from "../components";
import exportFromJSON from "export-from-json";
import Button from "../components/button";
import { useAppDispatch, useAppSelector } from "stores/store";
import DropDown from "../utils/drop-down";
import Input from "components/input";
import { IoIosRefresh } from "react-icons/io";
import { RiBrush2Fill } from "react-icons/ri";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { retail_bet_table_data, table_data1 } from "mock/table_data";

import {
  getFindBetsAction,
  getAllRetailersAction,
  getRetailerUsersAction,
} from "stores/ReatilerUser/retailerAction";
import {
  REPORT_TYPE_DROP_DOWN,
  DISPLAYCURRENCY,
  RETAILERS,
  OPERATOR,
  OPERATORGROUP,
  BET_TYPE_DROP_DOWN,
  GAME_DROP_DOWN,
  MARKET_DROP_DOWN,
  BET_STATUS,
} from "mock/mock";
import { toast } from "react-toastify";

const RetailBets = () => {
  const [selectedRetailers, setSelectedRetailers] = useState<string[]>([]);
  const [selectedRetailersId, setSelectedRetailersId] = useState<string[]>([]);
  const [selectedOpertaorGroup, setSelectedOpertaorGroup] = useState<string[]>(
    []
  );
  const [selectedOperator, setSelectedOperator] = useState<string[]>([]);
  const [selectedBetType, setSelectedBetType] = useState<string[]>([]);
  const [selectedGame, setSelectedGame] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string[]>([]);
  const [selectedBetStatus, setSelectedBetStatus] = useState<string[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);
  const [submittedBy, setSubmittedBy] = useState<string[]>([]);
  const [submittedById, setSubmittedById] = useState<string[]>([]);
  const [cancelledBy, setCancelledBy] = useState<string[]>([]);
  const [cancelledById, setCancelledById] = useState<string[]>([]);
  const [redeemedBy, setRedeemedBy] = useState<string[]>([]);
  const [redeemedById, setRedeemedById] = useState<string[]>([]);
  const [betId, setBetId] = useState("");
  const [slipReference, setSlipReference] = useState("");

  const Notification = ({
    message,
    type,
  }: {
    message: string;
    type: string;
  }) => {
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const dispatch = useAppDispatch();
  const retailers = useAppSelector((state) => state.retailer.retailers);
  const retailUsers = useAppSelector((state) => state.retailer.retailUsers);

  const tommorowDate = new Date();
  tommorowDate.setDate(tommorowDate.getDate() + 1);

  const [from, setFrom] = useState(new Date().toISOString());
  const [to, setTo] = useState<any>(tommorowDate);
  const [dates, setDates] = useState<any>("Today");
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [key, setKey] = useState(0);
  const [datesKey, setDatesKey] = useState(0);
  const [formdatesKey, setFromDatesKey] = useState(0);
  const [todateskey, setToDatesKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchFilteredData = async () => {
    const updatedDataFun = data.map((item) => {
      const slicedCreatedAt =
        item.created_at.slice(0, 10) + ", " + item.created_at.slice(11, 19);

      return {
        ...item,
        created_at: slicedCreatedAt,
        Datanumber: data.length,
      };
    });

    try {
      setLoading(true);
      const response = await dispatch(
        getFindBetsAction({
          startDate: from,
          endDate: to,
          retailers: selectedRetailersId,
          games: selectedGame,
          betType: selectedBetType,
          betStatus: selectedBetStatus,
          cancelledBy: cancelledById,
          redeemedBy: redeemedById,
          submittedBy: submittedById,
          betId: betId,
          slipReference: slipReference,
        })
      );

      if (response && response.payload) {
        setData(
          response.payload.map((item) => {
            const slicedCreatedAt =
              item.created_at.slice(0, 10) +
              ", " +
              item.created_at.slice(11, 19);
            return {
              ...item,
              created_at: slicedCreatedAt,
            };
          })
        );
      }

      setKey((prevKey) => prevKey + 1);
    } catch (error) {
      // Handle errors
      Notification({ message: "Something went wrong", type: "error" });
    } finally {
      setUpdatedData(updatedDataFun);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllRetailersAction());
      await dispatch(getRetailerUsersAction());
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchIdsFromRetailers = (namesArray) => {
      const ids = namesArray.map((name) => {
        const retailer = retailers.find(
          (retailer) => retailer.username === name
        );
        return retailer ? retailer._id : null;
      });
      return ids.filter((id) => id !== null); // Remove null values
    };

    setSelectedRetailersId(fetchIdsFromRetailers(selectedRetailers));
  }, [selectedRetailers]);

  useEffect(() => {
    const fetchIdsFromRetailUsers = (namesArray) => {
      const ids = namesArray.map((name) => {
        const retailer = retailUsers.find(
          (retailer) => retailer.username === name
        );
        return retailer ? retailer._id : null;
      });
      return ids.filter((id) => id !== null); // Remove null values
    };
    if (submittedBy.length > 0) {
      setSubmittedById(fetchIdsFromRetailUsers(submittedBy));
    }
    if (redeemedBy.length > 0) {
      setRedeemedById(fetchIdsFromRetailUsers(redeemedBy));
    }
    if (cancelledBy.length > 0) {
      setCancelledById(fetchIdsFromRetailUsers(cancelledBy));
    }
  }, [submittedBy, redeemedBy, cancelledBy]);

  useEffect(() => {
    const calculateFromToDate = (option) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      let fromDate, toDate;

      switch (option) {
        case "Today":
          fromDate = new Date();
          toDate = new Date(currentDate);
          break;
        case "Yesterday":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 1);
          toDate.setDate(toDate.getDate());
          break;
        case "This week":
          fromDate = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          toDate = new Date();
          break;
        case "Previous week":
          fromDate = new Date(
            currentDate.setDate(
              currentDate.getDate() -
                currentDate.getDay() +
                (currentDate.getDay() === 0 ? -6 : -13)
            )
          );
          toDate = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 7
            )
          );
          break;
        case "Last 7 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 7);
          break;
        case "Last 30 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 30);
          break;
        case "This Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth()
          );
          toDate = new Date();
          break;
        case "Previous Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1
          );
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
          break;

        default:
          break;
      }

      return { fromDate, toDate };
    };

    const { fromDate, toDate } = calculateFromToDate(dates);
    setFrom(fromDate);
    setTo(toDate);
    setFromDatesKey((prevKey) => prevKey + 1);
    setToDatesKey((prevKey) => prevKey + 1);
  }, [dates]);

  const handleClear = () => {
    setDates("Today");
    setSelectedGame([]);
    setRedeemedBy([]);
    setCancelledBy([]);
    setSubmittedBy([]);
    setDatesKey((prevKey) => prevKey + 1);
    setUpdatedData([]);
    setData([]);
  };

  useEffect(() => {
    setData([]);
  }, [dates]);

  const exportData = () => {
    const fileName = "retail-bets-data";
    if (!data) {
      throw new Error("No data provided for export.");
    }
    try {
      const exportType = exportFromJSON.types["csv"];
      exportFromJSON({ data, fileName, exportType });
    } catch (e) {
      Notification({
        message: "An error occured while exporting data",
        type: "error",
      });
    }
  };
  return (
    <main className="p-4">
      <h1 className="text-xl">Retail Bets</h1>

      <div className="w-full p-4 bg-gray-200">
        <p className="ml-3 font-bold">
          Please select desired filters and click the submit button to generate
          the report.
        </p>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-4">
            <div>
              <DropDown
                key={datesKey}
                label="Dates"
                options={[
                  "Today",
                  "Yesterday",
                  "This week",
                  "Previous week",
                  "Last 7 Days",
                  "Last 30 Days",
                  "This Month",
                  "Previous Month",
                  "Custom",
                ]}
                hadleSelected={(e) => setDates(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                From Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={formdatesKey}
                defaultDate={from}
                setDateTime={(e) => setFrom(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                To Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={todateskey}
                defaultDate={to}
                setDateTime={(e) => setTo(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retailers <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={retailers.map((retail) => retail.username)}
                selected={selectedRetailers}
                setSelected={setSelectedRetailers}
                onChange={setSelectedRetailers}
                includeSelectAll={true}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Game <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                includeSelectAll={true}
                options={GAME_DROP_DOWN}
                selected={selectedGame}
                setSelected={setSelectedGame}
                onChange={setSelectedGame}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Bet Status <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                includeSelectAll={true}
                options={BET_STATUS}
                selected={selectedBetStatus}
                setSelected={setSelectedBetStatus}
                onChange={setSelectedBetStatus}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Slip Reference <span className="text-grey-800">*</span>
              </h1>
              <input
                className="px-3 py-2 text-sm placeholder-gray-500 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 bg-gray-50"
                type="text"
                value={slipReference}
                onChange={(e) => setSlipReference(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Submited by <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={retailUsers.map((retail) => retail.username)}
                selected={submittedBy}
                setSelected={setSubmittedBy}
                onChange={setSubmittedBy}
                includeSelectAll={false}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Cancelled By <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={retailUsers.map((retail) => retail.username)}
                selected={cancelledBy}
                setSelected={setCancelledBy}
                onChange={setCancelledBy}
                includeSelectAll={false}
              />
            </div>{" "}
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Redeemed By<span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={retailUsers.map((retail) => retail.username)}
                selected={redeemedBy}
                setSelected={setRedeemedBy}
                onChange={setRedeemedBy}
                includeSelectAll={false}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Bet Id <span className="text-grey-800">*</span>
              </h1>
              <input
                className="px-3 py-2 text-sm placeholder-gray-500 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 bg-gray-50"
                type="text"
                value={betId}
                onChange={(e) => setBetId(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-x-10 gap-y-4">
            <Button onClick={fetchFilteredData}>
              <div className="flex items-center gap-2">
                <IoIosRefresh className="text-white" />
                <span>Submit</span>
              </div>
            </Button>
            <Button variant="secondary" onClick={exportData}>
              <div className="flex items-center gap-2">
                <BsFileEarmarkSpreadsheet />
                <span>Export to Excel</span>
              </div>
            </Button>
            <Button onClick={handleClear}>
              <div className="flex items-center gap-2">
                <RiBrush2Fill />
                <span>Clear</span>
              </div>
            </Button>

            <div className="p-2 text-white bg-blue-400 rounded">
              No of records:
              {" " + updatedData.length}
            </div>
          </div>
        </div>
        {loading && (
          <div className="absolute bg-gray-500 rounded-full top-1/3 left-1/2">
            <svg
              aria-hidden="true"
              className="w-16 h-16 p-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        )}

        <Table
          key={key}
          data={updatedData}
          columns={retail_bet_table_data.columns}
        />
      </div>
    </main>
  );
};

export default RetailBets;
