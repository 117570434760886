import React, { useState, useEffect } from "react";
import { BiSolidDashboard } from "react-icons/bi";
import { getRetailRevenueAction } from "stores/ReatilerUser/retailerAction";
import { useAppDispatch } from "stores/store";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  FaCoins,
  FaTrophy,
  FaTimesCircle,
  FaHandHoldingUsd,
  FaCheckCircle,
  FaBalanceScale,
  FaPercentage,
} from "react-icons/fa";

const Notification = ({ message, type }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({
    TotalBets: 0,
    TotalBetWinnings: 0,
    TotalCancellations: 0,
    TotalUnclaimed: 0,
    TotalRedeemed: 0,
    TotalNet: 0,
  });

  const handleDataFetch = async ({ fromDate, toDate }) => {
    try {
      setLoading(true);
      const response = await dispatch(
        getRetailRevenueAction({
          startDate: fromDate,
          endDate: toDate,
          games: [],
          retailers: [],
          slipReference: "",
        })
      );

      if (response && response.payload) {
        setData(response.payload);
        updateTotals(response.payload);
      } else {
        setData([]);
        updateTotals([]);
      }
    } catch (error) {
      Notification({ message: "Something went wrong", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const updateTotals = (data) => {
    let TotalBets = 0;
    let TotalBetWinnings = 0;
    let TotalCancellations = 0;
    let TotalUnclaimed = 0;
    let TotalRedeemed = 0;
    let TotalNet = 0;

    data.forEach((item) => {
      TotalBets += item.bets;
      TotalBetWinnings += item.betWinnings;
      TotalCancellations += item.cancellations;
      TotalUnclaimed += item.unclaimed;
      TotalRedeemed += item.redeemed;
      TotalNet += item.net;
    });

    setTotals({
      TotalBets,
      TotalBetWinnings,
      TotalCancellations,
      TotalUnclaimed,
      TotalRedeemed,
      TotalNet,
    });
  };

  const handleSelect = (option) => {
    setData([]);
    setSelectedDate([option]);

    const subtractDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() - days);
      return result.toISOString().split("T")[0];
    };

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString().split("T")[0];
    };

    const currentDate = new Date();

    if (option === "Today") {
      handleDataFetch({
        fromDate: currentDate.toISOString().split("T")[0],
        toDate: addDays(currentDate, 1),
      });
    } else if (option === "Yesterday") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 1),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "This Week") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, currentDate.getDay() - 1),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "Last Week") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 7 + currentDate.getDay()),
        toDate: subtractDays(currentDate, 1 + currentDate.getDay()),
      });
    } else if (option === "This Month") {
      handleDataFetch({
        fromDate: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`,
        toDate: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
      });
    } else if (option === "Last 7 days") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 6),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "Last 30 days") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 29),
        toDate: currentDate.toISOString().split("T")[0],
      });
    }
  };

  useEffect(() => {
    handleSelect("Today");
  }, []);

  const DataTable = ({ data }) => {
    const {
      TotalBets,
      TotalBetWinnings,
      TotalCancellations,
      TotalUnclaimed,
      TotalRedeemed,
      TotalNet,
    } = totals;

    const cardsData = [
      { label: "Total Bets", value: TotalBets, icon: <FaCoins /> },
      {
        label: "Total Bet Winnings",
        value: TotalBetWinnings,
        icon: <FaTrophy />,
      },
      {
        label: "Total Cancellations",
        value: TotalCancellations,
        icon: <FaTimesCircle />,
      },
      {
        label: "Total Unclaimed",
        value: TotalUnclaimed,
        icon: <FaHandHoldingUsd />,
      },
      {
        label: "Total Redeemed",
        value: TotalRedeemed,
        icon: <FaCheckCircle />,
      },
      { label: "Total Net", value: TotalNet, icon: <FaBalanceScale /> },
      {
        label: "RTP",
        value:
          TotalBets === 0
            ? "0%"
            : ((TotalBetWinnings / TotalBets) * 100).toFixed(2) + "%",
        icon: <FaPercentage />,
      },
    ];

    return (
      <div className="grid grid-cols-1 gap-6 m-8 sm:grid-cols-2 lg:grid-cols-3">
        {cardsData.map(({ label, value, icon }) => (
          <div
            key={label}
            className="p-6 transition-shadow duration-300 bg-white border border-gray-400 rounded-lg shadow-md hover:shadow-lg"
          >
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-blue-500 dark:text-blue-600">
                {icon}
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 dark:text-gray-700">
                  {label}
                </p>
                <p className="mt-2 text-3xl font-bold text-gray-900 dark:text-gray-600">
                  {value}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h1
        style={{
          color: "#1E1E1E",
          fontSize: "1.5rem",
          fontWeight: 500,
        }}
      >
        <span className="flex items-center gap-1">
          <BiSolidDashboard className="text-xl text-green-600" /> Dashboard
        </span>
      </h1>
      <h2 className="sm:mb-0">Select a date option to display data</h2>
      <div className="flex flex-col p-4 mx-auto mt-5 text-white sm:flex-row sm:items-center sm:space-x-4 bg-primary">
        <h3 className="sm:mb-0">REVENUE</h3>
        <div className="mt-3 sm:mt-0">
          <DateFilter selectedDate={selectedDate} handleSelect={handleSelect} />
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-96">
          <FaSpinner className="text-5xl animate-spin" />
        </div>
      ) : data.length === 0 ? (
        <DataTable data={[]} />
      ) : (
        <DataTable data={data} />
      )}
    </div>
  );
};

const FilterItem = ({ label, checked, onChange }) => {
  return (
    <span
      onClick={() => onChange(label)}
      className={`p-1 cursor-pointer transition-colors duration-200 ${
        checked
          ? "bg-black bg-opacity-30"
          : "bg-white bg-opacity-30 hover:text-blue-600"
      }`}
    >
      {label}
    </span>
  );
};

const DateFilter = ({ selectedDate, handleSelect }) => {
  const options = [
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last 7 days",
    "Last 30 days",
  ];

  return (
    <div className="">
      {options.map((option) => {
        return (
          <FilterItem
            key={option}
            label={option}
            checked={selectedDate.includes(option)}
            onChange={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default Dashboard;
