import { useState, useEffect } from "react";
import { useAppDispatch } from "stores/store";
import { Tab } from "@headlessui/react";
import Button from "components/button";
import { addOddAction, getOddAction } from "stores/Odd/oddAction";
import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

interface Odd {
  _id: string;
  provider: string;
  odds: { [key: string]: number };
  showOdds: boolean; // New property to track visibility
}

const Odds = () => {
  const dispatch = useAppDispatch();
  const [oddsData, setOddsData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const getOdds = async () => {
    try {
      const response = await dispatch(getOddAction());

      setOddsData(response.payload);
    } catch (error) {
      console.error("Error fetching odds:", error);
    }
  };

  useEffect(() => {
    getOdds();
  }, []);

  const flattenOdds = (odds) => {
    let flattened = [];
    for (let outerKey in odds) {
      for (let innerKey in odds[outerKey]) {
        flattened.push({
          outerKey,
          innerKey,
          value: odds[outerKey][innerKey],
        });
      }
    }
    return flattened;
  };

  const selectedOdds = oddsData.find((odd) => odd._id === selectedProvider);

  return (
    <>
      <div>
        <label className="ml-5">Select Provider: </label>
        <select
          className="py-2 pr-10 mt-2 ml-5 border border-gray-300 rounded-md xs:w-full"
          value={selectedProvider}
          onChange={handleProviderChange}
        >
          <option value="" disabled>
            Select Provider
          </option>
          {oddsData.map((odd) => (
            <>
              <option key={odd._id} value={odd._id}>
                {odd.provider}
              </option>
            </>
          ))}
        </select>
        <div className="flex mt-5">
          <button
            onClick={() => {
              setShowData(true);
            }}
            className="px-3 py-1 ml-5 text-white bg-blue-500 rounded-md"
          >
            Show Odds
          </button>
          <button
            onClick={() => {
              setShowData(false);
            }}
            className="px-3 py-1 ml-5 text-black border border-black rounded-md"
          >
            Hide Odds
          </button>
        </div>
        {selectedProvider && selectedOdds && showData && (
          <table className="w-full mx-auto mt-5">
            <thead>
              <tr>
                <th>Number of selection</th>
                <th>Number of winning</th>
                <th>Odd value</th>
              </tr>
            </thead>

            <tbody>
              {flattenOdds(selectedOdds.odds).map((odd, index) => (
                <tr
                  key={index}
                  className="mx-auto text-center border border-gray-300"
                >
                  <td>{odd.outerKey}</td>
                  <td>{odd.innerKey}</td>
                  <td>{odd.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const AddOdds = () => {
  const dispatch = useAppDispatch();
  const [provider, setProvider] = useState("");
  const [odds, setOdds] = useState(null);

  const placeholder =
    'Enter odds in JSON format, example: { "1": { "1": 1.5 }, "2": { "1": 1.5, "2": 2.5 }, "3": { "1": 1.5, "2": 2.5, "3": 3.5 } }';

  const addodd = async () => {
    try {
      const response = await dispatch(
        addOddAction({ provider, odds: JSON.parse(odds?.replace(/\n/g, "")) })
      );
      if (response.payload !== undefined) {
        Notification({
          message: "Odds added successfully",
          type: "success",
        });
        window.location.reload();
      } else {
        Notification({
          message:
            "Something went wrong, please check the provider name and odds format.",
          type: "error",
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching odds:", error);
      Notification({
        message:
          "Something went wrong, please check the provider name and odds format.",
        type: "error",
      });
      window.location.reload();
    }
  };

  return (
    <>
      <div>
        <label>Add New odds: </label>
        <div className="flex justify-center mt-5">
          <input
            className="w-1/2 p-3 mt-2 mr-auto border border-gray-300 rounded-md"
            placeholder="provider name"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
          />
        </div>
        <div className="flex justify-center mt-5">
          <textarea
            className="w-1/2 p-3 mt-2 mb-3 mr-auto border border-gray-300 rounded-md h-80"
            placeholder={placeholder}
            value={odds}
            onChange={(e) => setOdds(e.target.value)}
          ></textarea>
        </div>
        <button
          onClick={addodd}
          className="px-3 py-1 ml-5 text-white bg-blue-500 rounded-md"
        >
          Add Odd
        </button>
        <button
          onClick={() => {}}
          className="px-3 py-1 ml-5 text-black border border-black rounded-md"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

const tabList = [
  {
    name: "Odds",
    tab: <Odds />,
  },
  {
    name: "Add Odds",
    tab: <AddOdds />,
  },
];

const Odd = () => {
  return (
    <div>
      <h1 className="font-[600]">Manage Odds</h1>
      <div className="p-1 bg-gray-300">
        <Tab.Group>
          <Tab.List className="bg-gray-300">
            {tabList.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) => `border border-gray-500
                px-4 py-2 rounded-md 
                rounded-b-none
                focus:outline-none focus:ring-transparent ring-offset-2 ring-offset-gray-300 ring-white
                ${selected ? "bg-white border-b-white" : "bg-gray-300"}
                `}
              >
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="px-5 bg-white border-gray-500">
            {tabList.map((tab) => (
              <Tab.Panel key={tab.name} className="p-5 rounded-md ">
                {tab.tab}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="flex justify-center mt-5">
        <Button variant="secondary" onClick={() => window.history.back()}>
          <div>Back to List</div>
        </Button>
      </div>
    </div>
  );
};

export default Odd;
