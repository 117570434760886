import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Suspense } from "react";
import {
  BalanceAdjustment,
  BankOfficeRoles,
  BankOfficeUsers,
  CashierBalances,
  Dashboard,
  ErrorPage,
  RetailBets,
  RetailRevenue,
  RetailRoles,
  RetailUserRevenue,
  RetailUsers,
  Retailers,
  Operators,
  RetailActivity,
  Login,
  ForgotPassword,
  RetailerDetail,
  BackOfficeUserDetail,
  RetailerUserDetail,
  Odd,
} from "./pages/pages-Index";
import RootLayout from "layout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RootLayout />}>
        <Route
          path="/"
          element={
            localStorage.getItem("userRole") != "retail_support" ? (
              <Dashboard />
            ) : (
              <Retailers />
            )
          }
        />
        <Route path="/Retail-revenue" element={<RetailRevenue />} />
        <Route path="/Retail-bets" element={<RetailBets />} />
        <Route path="/Retail-user-revenue" element={<RetailUserRevenue />} />

        <Route path="/Retailer-detail" element={<RetailerDetail />} />
        <Route path="/Retail-user-detail" element={<RetailerUserDetail />} />

        <Route path="/Retail-activity" element={<RetailActivity />} />
        <Route path="/Operators" element={<Operators />} />
        <Route path="/Bank-office-users" element={<Outlet />}>
          <Route index element={<BankOfficeUsers />} />
          <Route path="Detail" element={<BackOfficeUserDetail />} />
        </Route>
        <Route path="/odd" element={<Odd />} />
        <Route path="/Bank-office-roles" element={<BankOfficeRoles />} />
        <Route path="/Retail-users" element={<RetailUsers />} />
        <Route path="/Retailers" element={<Retailers />} />
        <Route path="/Retail-roles" element={<RetailRoles />} />
        <Route path="/Balance-adjustment" element={<BalanceAdjustment />} />

        <Route path="/Cashier-balances" element={<CashierBalances />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
      <Route path="/Login" element={<Login />} />
      <Route path="/Forgot-password" element={<ForgotPassword />} />
    </Route>
  )
);

function App() {
  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
