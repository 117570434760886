const Footer = () => {
  const thisYear = new Date().getFullYear();
  return (
    <div className="w-full p-4 text-xs text-center text-gray-500 ">
      @{thisYear}- EtVirtual
    </div>
  );
};

export default Footer;
