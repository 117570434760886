import { createSlice } from "@reduxjs/toolkit";
import { getOddAction, addOddAction } from "./oddAction";
import { OddInterface } from "../../models/odd";

const initialState: OddInterface = {
  provider: "",
  odds: {},
  _id: "",
};

const OddSlice = createSlice({
  name: "Odd",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOddAction.fulfilled, (state: OddInterface, action) => {
      if (action.payload !== undefined) state.odds = action.payload;
    });
    builder.addCase(addOddAction.fulfilled, (state: OddInterface, action) => {
      if (action.payload !== undefined) state.odds = action.payload;
    });
  },
});

export default OddSlice.reducer;
