import { createAsyncThunk } from "@reduxjs/toolkit";
import { Oddaxios } from "../../services/api/axios";
import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const getOddAction = createAsyncThunk("getOdd", async (thunkApi) => {
  try {
    const response = await Oddaxios.get("odd");
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 402) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      // Notification({
      //   message: "Session Expired",
      //   type: "error",
      // });
      window.location.href = "/login";
    } else if (error.response && error.response.status === 401) {
      // Notification({
      //   message: "Session Expired",
      //   type: "error",
      // });
      window.location.href = "/login";
    } else {
      Notification({
        message: "Something went wrong",
        type: "error",
      });

      window.location.href = "/login";
    }
  }
});

export const addOddAction = createAsyncThunk(
  "Retailers/addOdd",
  async (
    {
      provider,
      odds,
    }: {
      provider: string;
      odds: {
        [key: string]: {
          [key: string]: number;
        };
      };
    },
    thunkApi
  ) => {
    try {
      const response = await Oddaxios.post(`odd`, {
        provider,
        odds,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        // Notification({
        //   message: "Session Expired",
        //   type: "error",
        // });
        window.location.href = "/login";
      } else {
      }
    }
  }
);
